import config from '@/api-services/_config';

export default {

  checkStatus(data) {
    return fetch(`${config.url}/livesupport:index/check-status`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  initiateRequest(data) {
    // todo:: remove this line
    // return fetch(`http://ezaiphp.localhost:8081/livesupport:index/initiate-request`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
    return fetch(`${config.url}/livesupport:index/initiate-request`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  fetchAllModels(data) {
    return fetch(`${config.url}/livesupport:index/get-models`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  fetchChats(data) {
    return fetch(`${config.url}/livesupport:index/get-chats`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  requestForContactEmailNotify(data) {
    return fetch(`${config.url}/livesupport:index/contact-request-with-details`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  }
  
};
