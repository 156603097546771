<template>
  <div class="menu h-100" :class="{ 'visible': visible }">
    <div class="sidebar d-flex flex-column">
      <div class="px-3">
        <div class="int-header bg-transparent d-lg-none">
          <button type="button" class="close-bt" @click="visible = false">
            <svg width="26" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>
          <img class="logo d-collapsed-none ms-2" :src="logo" />
        </div>
      </div>
      <div class="nav flex-grow-1 overflow-auto d-flex flex-nowrap flex-column">
        <router-link :to="'/deploy'" class="p-3 d-flex justify-content-center align-items-center text border-bottom mb-2">
          <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1.5L1 7.5L7 13.5" stroke="#0F172A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="d-flex flex-column  mx-4">
            <span class="fw-bold">{{ selectedDeployment?.name }}</span>
            <span class="text-muted">Live Chats</span>
          </div>
        </router-link>
        <div class="nav overflow-auto d-flex flex-nowrap flex-column px-3">
          <ul v-if="loading" class="d-flex justify-content-center mt-3"><div v-show="showLoading" class=" spinner-border"></div></ul>
          <!-- <div class="small fw-bold no-chats text-center text-muted" v-else-if="!assistants || !assistants.length">
            There are no conversations matching this criteria
          </div> -->
          <template v-else>
            <ul class="nav flex-column mx-n2">
              <template v-for="conversation in liveSupportChats">
                <li class="nav-item mw-100" :key="`room-${conversation.id}`" :class="{ 'selected': selectedLiveSupportChat.id == conversation.id }">
                  <router-link @click.native="selectConversation(conversation)" :to="`/live-support/chats/${conversation.model_id}?room_id=${conversation.room_id}`" class="nav-link px-2 text-muted d-flex align-items-center px-0">
                    <AssistantThumbnail :assistant="{hexcolor:null}" />
                    <div class="flex-grow-1 text-truncate d-collapsed-none">
                      <div class="fw-medium">
                        {{ conversation.member_name || `Visitor ${conversation.participent_member_id}` }}
                      </div>
                    </div>
                    
                    <!-- <a @click.prevent="archivePastChat(conversation)" href="#" class="delete-bt d-none" data-bs-toggle="tooltip" title="Archive">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 6.66634C16.2754 6.66634 16.6993 6.49075 17.0118 6.17819C17.3244 5.86563 17.5 5.4417 17.5 4.99967C17.5 4.55765 17.3244 4.13372 17.0118 3.82116C16.6993 3.5086 16.2754 3.33301 15.8333 3.33301H4.16667C3.72464 3.33301 3.30072 3.5086 2.98816 3.82116C2.67559 4.13372 2.5 4.55765 2.5 4.99967C2.5 5.4417 2.67559 5.86563 2.98816 6.17819C3.30072 6.49075 3.72464 6.66634 4.16667 6.66634M15.8333 6.66634H4.16667M15.8333 6.66634V14.9997C15.8333 15.4417 15.6577 15.8656 15.3452 16.1782C15.0326 16.4907 14.6087 16.6663 14.1667 16.6663H5.83333C5.39131 16.6663 4.96738 16.4907 4.65482 16.1782C4.34226 15.8656 4.16667 15.4417 4.16667 14.9997V6.66634M8.33333 9.99967H11.6667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a> -->
                    <!-- <a v-else @click.prevent="unarchiveAssistant(assistant)" href="#" class="delete-bt d-none" data-bs-toggle="tooltip" title="Unarchive">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 6.66634C16.2754 6.66634 16.6993 6.49075 17.0118 6.17819C17.3244 5.86562 17.5 5.4417 17.5 4.99967C17.5 4.55765 17.3244 4.13372 17.0118 3.82116C16.6993 3.5086 16.2754 3.33301 15.8333 3.33301H4.16667C3.72464 3.33301 3.30072 3.5086 2.98816 3.82116C2.67559 4.13372 2.5 4.55765 2.5 4.99967C2.5 5.4417 2.67559 5.86562 2.98816 6.17819C3.30072 6.49075 3.72464 6.66634 4.16667 6.66634M15.8333 6.66634H4.16667M15.8333 6.66634V14.9997C15.8333 15.4417 15.6577 15.8656 15.3452 16.1782C15.0326 16.4907 14.6087 16.6663 14.1667 16.6663H5.83333C5.39131 16.6663 4.96738 16.4907 4.65482 16.1782C4.34226 15.8656 4.16667 15.4417 4.16667 14.9997V6.66634M10 9.5V13.5M10 9.5L11.6667 11.1667M10 9.5L8.33333 11.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a> -->
                  </router-link>
                </li>
              </template>
              <div v-if="!liveSupportChatsFullLoad" class="d-flex my-3 justify-content-center">
                <button v-if="liveSupportChats.length" type="button" class="btn btn-secondary" @click.prevent="liveSupportChatsLoadMore">
                  {{ liveSupportChatsLoading ? 'Loading..' : '+ See more'}}
                </button>
              </div>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/main';
  import AssistantThumbnail from '@/components/models/thumbnail';
  import DeployService from '@/api-services/deploy.service';
  import livesupportService from '@/api-services/livesupport.service';
  export default {
    name: 'MainMenuLiveSupport',
    components:{
      AssistantThumbnail,
    },
    data() {
      return {
        view_archived: 0,
        visible: false,
        sidebarWidth: null,
        isMobile: false,
        loading: true,
        showLoading: localStorage.getItem('collapsedMenu') == 'false' ? true : false,
        editText: '',
        editing: false,
        ezaiText: 'EZAI.io Assistant',
        countNotification: 0,
        showArchived: 0,
        selectedDeployment: null,
        liveSupportChatsPagination:{
          skip: 0,
          take: 20,
        },
        liveSupportChatsFullLoad: false,
        liveSupportChatsLoading: false,
        search: '',
        sort_by: 'new-old',
        liveSupportChats: [],
      }
    },
    computed: {
      logo() {
        return require(`@/assets/images/logo.svg`);
      },
      selectedLiveSupportChat() {
        return this.$store.state.selectedLiveSupportChat || {};
      }
    },
    async mounted() {
      this.fetchLiveSupportChats();
      if(!this.selectedDeployment){
        this.selectedDeployment = await DeployService.getDeployById({deploy_id:this.$route.params.id}).then( res => res.deploy );
      }
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth < 700;
        this.sideBarWidth = this.isMobile ? '100%' : localStorage.getItem('sidebarWidth');
      });
      window.dispatchEvent(new Event('resize'));

      this.loading = false;
      while(!this.$root.$children[0].socket) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      EventBus.$on('live_support_request_received', this.updateLiveSupportChats);
      EventBus.$on('accepted_live_chat_response', this.updateLiveSupportChats);
      EventBus.$on('finish_live_chat_response', this.updateLiveSupportChats);
    },
    methods: {
      updateLiveSupportChats(data) {
        this.liveSupportChats = this.liveSupportChats.map(e => {
          if(e.id == data.id) {
            e.date_accepted = data.date_accepted;
            e.date_ended = data.date_ended;
          }
          return e;
        });
      },
      fetchLiveSupportChats() {
        this.loading = true;
        livesupportService.fetchChats({model_id:this.$route.params.id, skp: this.liveSupportChatsPagination.skip, take: this.liveSupportChatsPagination.take}).then((response) => {
          this.loading = false;
          this.liveSupportChats = response.chats;
          if(this.liveSupportChats.length) {
            this.selectConversation(this.liveSupportChats[0]);
          }
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        });
      },
      async liveSupportChatsLoadMore() {
        this.liveSupportChatsPagination.skip += this.liveSupportChatsPagination.take;
        this.liveSupportChatsLoading = true;

        livesupportService.fetchChats({model_id:this.$route.params.id, skip: this.liveSupportChatsPagination.skip, take: this.liveSupportChatsPagination.take}).then((response) => {
          this.liveSupportChatsLoading = false;
          this.liveSupportChats = [...this.liveSupportChats, ...response.chats];
          if(response.chats.length < this.liveSupportChatsPagination.take) {
            this.liveSupportChatsFullLoad = true;
          }
        }).catch((error) => {
          this.liveSupportChatsLoading = false;
          console.log(error);
        });
      },
      async selectConversation(conversation) {
        let liveSupportStatus = 'AI';
        if(!conversation.date_accepted) {
          liveSupportStatus = 'WAITING_FOR_LIVE_SUPPORT';
        } 

        if(conversation.date_accepted && !conversation.date_ended) {
          liveSupportStatus = 'LIVE_SUPPORT';
        }

        this.$store.commit('setChatLiveSupportStatus', liveSupportStatus);
        this.$store.commit('setSelectedLiveSupportChat', conversation);
        this.selectRoom(conversation.room_id);
      },
      formatLastUsed(date) {
        const currentDate = new Date();
        const conversationDate = new Date(date);
        const timeDiff = Math.abs(currentDate.getTime() - conversationDate.getTime());
        const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
        const hoursDiff = Math.floor(timeDiff / (1000 * 3600));

        if (daysDiff > 0) {
          return `${daysDiff} days ago`;
        } else if (hoursDiff > 0) {
          return `${hoursDiff} hours ago`;
        } else {
          const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
          return conversationDate.toLocaleDateString(undefined, options);
        }
      },
      togglePanel() {
        this.showLoading = !this.showLoading;
        this.$emit('toggleMenu');
      },
      toggle() {
        this.visible = !this.visible;
        this.$root.$children[0].$refs.sideBar.collapsed = true;
        this.$root.$children[0].$refs.navBar.collapse();
      },
      selectRoom(id) {
        this.visible = false;
        EventBus.$emit('selectRoom', id);
        this.$logActivity({ action: 'click', page: '/live-support/chats', type: 'select_room', ref_id: id });
      },

    },
    watch: {
      '$route.query.room_id'(val) {
        this.selectRoom(val);
      },
    }
  }
</script>


<style lang="scss" scoped>
.menu {
  transition: all .3s !important;
  .sidebar {
    transition: all .3s !important;
    box-shadow: var(--shadow-right);
    background: var(--bs-base);
    min-height: 100%;
    height: calc(var(--vh, 1vh) * 100 - 61px);
    max-width: calc(100vw) !important;
    position: relative;
    z-index: 10;
    .nav {
      .nav-item {
        position: relative;
        .nav-link {
          position: relative;
          border-radius: 7px;
          color: var(--bs-secondary) !important;
          font-size: 13px;
        }
        &.selected {
          .nav-link {
            background: var(--bs-selected) !important;
          }
        }
        &:hover {
          .delete-bt {
            display: flex !important;
          }
          .edit-bt {
            display: block !important;
          }
        }
        .edit-bt {
          position: absolute;
          z-index: 2;
          right: 27px;
          top: 6px;
        }
      }
    }
    .theme-selector {
      .theme-dropdown {
        min-width: 0 !important;
        margin-bottom: 10px !important;
      }
      .d-grid {
        grid-template-columns: repeat(2, 1fr);
        .c {
          width: 54px;
          height: 54px;
          .circle {
            width: 30px;
            height: 30px;
            display: block;
            box-sizing: content-box;
            border-radius: 100%;
            border: 1px solid rgb(127, 127, 127);
          }
        }
      }
    }
  }
  :deep(.resizable-component) {
    height: 100% !important;
  }
}

:deep(.preferences.collapsed) {
  .dropdown {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }
  .pref-bt {
    svg { margin: 0 !important; }
    .text { display: none; }
  }
}
@media (max-width: 991px) {
  .menu {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100 - 60px);
    left: -100vw;
    transition: left .2s;
    width: 100vw !important;
    max-width: 100vw !important;
    background: rgba(0,0,0,.6);
    &.visible {
      left: 0;
    }
    .close-bt {
      position: absolute;
    }
  }
}
</style>
