import AuthService from './auth.service';
let url = process.env.VUE_APP_API_URL || 'https://api.ezai.io';
let urlAuth = process.env.VUE_APP_AUTH_URL || 'https://auth.ezai.io';
let urlAuthAPI = process.env.VUE_APP_AUTHAPI_URL || 'https://authapi.ezai.io';

// remove the .ezai.io version of this cookie as it's overriding the chat.ezai.io version and
// breaking the page on refresh for some people (like steven/lawrence). might have been legacy
// code because it doesn't get set anywhere else. can probably remove this later.
// localStorage.removeItem("ezai_auth_session_cookie");
// document.cookie = "ezai_auth_session_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.ezai.io"; // remove this after testing localstorage implementation

const urlParams = new URLSearchParams(window.location.search);
let sessionValue = getLocalStorageItemWithExpiry("ezai_auth_session_cookie");
// let sessionValue = getCookie("ezai_auth_session_cookie"); // remove this after testing localstorage implementation
let memberId;
let memberName;
let memberEmail;
let memberInitials;

let isDeployedApp = false;
let deployKey = getHostKey();
let deploymentObject = null;
if(process.env.NODE_ENV === 'development'){
//  deployKey = "zoho";
//  isDeployedApp = true;
}

if(deployKey) {
  // use custom api url as well
  url = `https://${deployKey}.api.ezai.io`;
  isDeployedApp = true;
}

document.cookie = "ezai_deploy=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

let setDeploymentObject = (deploy) => {
  deploymentObject = deploy;
}

const token = urlParams.get('token');
if(token) {
  // Set the cookie with a 1-day expiration
  setLocalStorageItemWithExpiry("ezai_auth_session_cookie", token);
  // document.cookie = `ezai_auth_session_cookie=${token}; expires=${expirationDate.toUTCString()}; path=/`; // remove this after testing localstorage implementation
  // Remove the token parameter from the URL and update the URL
  sessionValue = token;
  const urlParams2 = new URL(window.location.href);
  urlParams2.searchParams.delete('token');
  window.history.replaceState({}, '', urlParams2.toString());
}

let checkSession = async() => {
  if(memberId) return memberId;
  const response = await AuthService.checkSession();
  if(response.member){
    memberId = response.member_id
    if(response.member && response.member.user_name){
      memberName = response.member.user_name;
      memberEmail = response.member.email;
      memberInitials = response.member.initials;
    }
  }
  return memberId;
}

// remove this after testing localstorage implementation
// function getCookie(name) {
//   const cookies = document.cookie.split("; ");
//   for(let i = 0; i < cookies.length; i++) {
//     const cookie = cookies[i].split("=");
//     if(cookie[0] === name) {
//       return cookie[1];
//     }
//   }
//   return null;
// }

/**
 * Sets a value in the local storage with an expiry date.
 * @param {string} key - The key to store the value under.
 * @param {any} value - The value to be stored.
 * @param {number} [days=365] - The number of days until the value expires. Default is 365 days.
 * 
 * @author Muhammad Imran Israr<mimranisrar6@gmail.com>
 */
function setLocalStorageItemWithExpiry(key, value, days = 365) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const item = {
    value,
    expiry: expirationDate.getTime(),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Retrieves a value from local storage with expiry.
 * @param {string} key - The key of the item to retrieve.
 * @returns {any} The value of the item if it exists and has not expired, otherwise null.
 * 
 * @author Muhammad Imran Israr<mimranisrar6@gmail.com>
 */
function getLocalStorageItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
      return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}

/**
 * Extract key from hostname: KEY.app.ezai.io
 * Or for testing, from URL param: ?debug_host=KEY
 *
 * return {String}
 */
function getHostKey() {
  const debugHost = urlParams.get('debug_host');
  if(debugHost) {
    return debugHost;
  }

  const match = window.location.hostname.match(/^([a-z0-9-]+)\.app\.ezai\.io$/);
  return match ? match[1] : '';
}

const makeFormData = obj => {
  const fd = new FormData();
  Object.entries(obj).forEach(([k, v]) => {
    if(k.endsWith('[]') && Array.isArray(v)) {
      v.forEach(value => fd.append(k, String(value)));
    } else {
      fd.append(k, String(v));
    }
  });
  return fd;
};

function getMember() {
  return {memberName, memberEmail, memberInitials}
}

export default { url, urlAuth, urlAuthAPI, makeFormData, sessionValue, checkSession, deployKey,
  isDeployedApp, setDeploymentObject, deploymentObject, memberId, getMember};
