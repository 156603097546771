import config from '@/api-services/_config';

export default {
  
  fetch(data) {
    return fetch(`${config.url}/notifications:index/get-notifications`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  markAllRead(){
    return fetch(`${config.url}/notifications:index/mark-all-read`, { method: 'POST'}).then(res => res.json());
  },

  markRead(data){
    return fetch(`${config.url}/notifications:index/mark-read`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  fetchLiveChatCount(){
    return fetch(`${config.url}/notifications:index/get-live-chat-count`, { method: 'POST'}).then(res => res.json());
  }
  
};
