<template>
    <div>
        <a href="#" class="nav-link main px-0 py-3 mx-2 notifications d-flex align-items-center" id="navbarNotificationsScrollingDropdown" role="button" data-bs-toggle="dropdown">
        <div class="d-lg-none">Notifications</div>
        <div class="num">{{ notificationsCount }}</div>
        <svg class="d-lg-block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.857 15.082C14.7202 14.8614 16.5509 14.4217 18.311 13.772C16.8204 12.1208 15.9967 9.9745 16 7.75V7.05V7C16 5.4087 15.3678 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 9.99999 1C8.40869 1 6.88257 1.63214 5.75735 2.75736C4.63213 3.88258 3.99999 5.4087 3.99999 7V7.75C4.00301 9.97463 3.17898 12.121 1.68799 13.772C3.42099 14.412 5.24799 14.857 7.14299 15.082M12.857 15.082C10.959 15.3071 9.041 15.3071 7.14299 15.082M12.857 15.082C13.0011 15.5319 13.0369 16.0094 12.9616 16.4757C12.8862 16.942 12.7018 17.384 12.4234 17.7656C12.1449 18.1472 11.7803 18.4576 11.3592 18.6716C10.9381 18.8856 10.4724 18.9972 9.99999 18.9972C9.52761 18.9972 9.06191 18.8856 8.6408 18.6716C8.21968 18.4576 7.85506 18.1472 7.57661 17.7656C7.29816 17.384 7.11375 16.942 7.0384 16.4757C6.96305 16.0094 6.99889 15.5319 7.14299 15.082" stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </a>
        <div class="notification-dropdown-menu dropdown-menu dropdown-menu-end" aria-labelledby="navbarNotificationsScrollingDropdown">
        <div v-for="notification in notifications" :key="notification.id">
            <div v-if="notification.url" class="notification-item" @click="markRead(notification.id)">
                <router-link :to="notification.url">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="thumb me-2">
                                A
                            </div>
                            <div class="chat-bubbles w-100 d-flex flex-grow-1 flex-column justify-content-end align-items-start">
                            <div class="fw-bold">{{notification.title}}</div>
                            <div class="text">{{notification.content}}</div>
                            <div class="text-muted">{{humanReadableDate(notification.date_added)}}</div>
                            </div>
                        </div>
                    </div>
                </router-link>    
            </div>
            
            <div class="notification-item" v-else @click="markRead(notification.id)">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="thumb me-2">
                            A
                        </div>
                        <div class="chat-bubbles w-100 d-flex flex-grow-1 flex-column justify-content-end align-items-start">
                        <div class="fw-bold">{{notification.title}}</div>
                        <div class="text">{{notification.content}}</div>
                        <div class="text-muted">{{humanReadableDate(notification.date_added)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mark-all-read" @click="markAllRead">
            <p>Mark all read</p>
        </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NotificationCard',
    props: {
        notificationsCount: {
            default: 0
        },
        notifications: {
            default: []
        }
    },
    data() {
        return {
        }
    },
    computed: {
        root() {
            return this.$root.$children[0];
        },
        socket() {
            return this.root.socket;
        },
    },
    async mounted() {
    },
    methods: {
        
        humanReadableDate(date) {
            const currentDate = new Date();
            const conversationDate = new Date(date);
            const timeDiff = Math.abs(currentDate.getTime() - conversationDate.getTime());
            const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
            const hoursDiff = Math.floor(timeDiff / (1000 * 3600));

            if (daysDiff > 0) {
            return `${daysDiff} days ago`;
            } else if (hoursDiff > 0) {
            return `${hoursDiff} hours ago`;
            } else {
            const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
            return conversationDate.toLocaleDateString(undefined, options);
            }
        },
        markAllRead(){
            this.$emit('markAllRead');
        },
        markRead(id){
            this.$emit('markRead', id);
        }

    }

}

</script>

<style scoped lang="scss">
    .navbar{
        .notifications {
            position: relative;
            .num {
            position: absolute;
            font-size: 10px;
            color: #fff;
            min-width: 13px;
            height: 13px;
            margin: -13px -2px 0 0;
            border-radius: 12px;
            padding: 0 2px 1px;
            background: #dc2631;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            top: 50%;
            left: 50%;
            @media (max-width: 991px) {
                position: static;
                margin: 0 0 0 10px;
                min-width: 18px;
                height: 18px;
                font-size: 14px;
            }
            }
        }
        .notification-dropdown-menu {
        right: 11%;
        width: 369px;
        max-height: 300px;
        overflow-y: scroll;
        > .card {
        min-width: 236px;
        }
        .dropdown {
        .submenu {
            position: absolute;
            display: none;
            transform: translateX(-100%);
            top: 0;
        }
        &:hover {
            .submenu {
            display: block !important;
            }
        }
        }
        .thumb {
        width: 32px;
        height: 32px;
        font-size: 12px;
        font-weight: 600;
        background: #FBE6C9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        }
        @media (max-width: 991px) {
        display: block !important;
        background: none !important;
        box-shadow: none !important;
        .card {
            box-shadow: none !important;
        }
        }
        }

        .notification-item{
            padding: 16px 12px 16px 12px;
            background: #F2F5F8;
            border-bottom: solid 1px #E2E8F0;
            cursor: pointer;
        }
        .mark-all-read{
            cursor: pointer;
            display: flex;
            justify-content: center;
        }
    }    
</style>
